import Moment from "moment"

export function formatDate(d: Date) {
    return Moment(d).format("YYYY-MM-DD HH:mm:ss")
}

export function sanitizeProps(props: any) {
    const { activeProject, dispatch, isEdit, infoArea, ...rest } = props
    return rest
}

export function getCollectionFieldConfig(collection: any, field: string) {
    field = field?.replace(/\[[0-9]+\]/, "")
    let fieldParts = field?.split(".") || []
    let fields = collection.fields || []
    let conf
    for (let i = 0; i < fieldParts.length; i++) {
        const fPart = fieldParts[i]
        for (let fi = 0; fi < fields.length; fi++) {
            const fConf = fields[fi]
            if (fConf.name === fPart) {
                conf = fConf
                fields = fConf.subFields || []
                break
            }
        }
    }

    return conf
}

// no conversation, direct json download
export function JSONExporter(filePrefix: string): Function {
    return (items: any[]) => {
        // Creating the blob file and its url
        var blob = new Blob([JSON.stringify(items, undefined, 4)], {
            type: "application/json",
        })
        let url = window.URL.createObjectURL(blob)

        var date = Moment(new Date()).format("YYYY_MM_DD-HH_mm_ss")

        // Creating the hyperlink and auto click it to start the download
        let link = document.createElement("a")
        link.href = url
        link.download = filePrefix + "_" + date + ".json"
        link.click()
    }
}
