import { createMuiTheme } from "@material-ui/core/styles"
import red from "@material-ui/core/colors/red"

let Theme = createMuiTheme({
    palette: {
        secondary: {
            main: "#7c0505",
            light: "#b23e2e",
            dark: "#4c0000",
        },
        primary: {
            main: "#5d4037",
            light: "#8b6b61",
            dark: "#321911",
        },
        error: red,
        contrastThreshold: 3,
    },
    overrides: {
        // @ts-ignore
        RaLayout: {
            appFrame: {
                flex: 1,
            },
            root: {
                backgroundColor: "#888",
            },
            content: {
                backgroundColor: "white",
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: "#524f4b!important",
            },
            docked: {
                backgroundColor: "#524f4b!important",
            },
        },
        RaMenuItemLink: {
            root: {
                color: "white",
            },
            active: {
                color: "white",
                fontWeight: "bold",
            },
            icon: {
                color: "white",
            },
        },
        RaRichTextInput: {
            label: {
                fontSize: "1.4em",
                fontWeight: "bold",
            },
        },
        MuiTypography: {
            root: {
                whiteSpace: "pre-line",
            },
        },
        MuiTableCell: {
            root: {
                verticalAlign: "top",
            },
        },
    },
})

export default Theme
