import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { routerMiddleware, connectRouter } from "connected-react-router"
import createSagaMiddleware from "redux-saga"
import { all, fork } from "redux-saga/effects"
import {
    adminReducer,
    adminSaga,
    USER_LOGOUT,
    registerResource,
} from "react-admin"

function persistActiveProject(record: any) {
    localStorage.setItem("activeProject", JSON.stringify(record))
}

export default (params: any) => {
    const { authProvider, dataProvider, history } = params

    let activeProject: undefined | any = null
    try {
        const str = localStorage.getItem("activeProject")
        if (typeof str === "string") {
            activeProject = JSON.parse(str)
        }
    } catch (e) {
        // ignore invalid local storage
    }

    // our custom reducer
    const projectReducer = (previousState = activeProject, action: any) => {
        switch (action.type) {
            case "SET_ACTIVE_PROJECT":
                persistActiveProject(action.payload)
                return action.payload

            case "RA/CLEAR_STATE":
                localStorage.removeItem("activeProject")
                return null

            case "RA/CRUD_GET_LIST_SUCCESS":
                if (action.meta?.resource === "project") {
                    // TODO set first project if only one without filter
                    const projects: Array<any> = action.payload?.data || []
                    for (let i = 0; i < projects.length; i++) {
                        if (previousState?.id === projects[i].id) {
                            // update project with new data
                            persistActiveProject(projects[i])
                            return projects[i]
                        }
                    }
                }
                break

            case "RA/CRUD_GET_ONE_SUCCESS":
            case "RA/CRUD_UPDATE_SUCCESS":
                if (action.meta?.resource === "project") {
                    if (previousState?.id === action.payload?.data?.id) {
                        // update project with new data
                        persistActiveProject(action.payload.data)
                        return action.payload.data
                    }
                }
                break
        }

        return previousState
    }
    const infoAreaReducer = (previousState: Array<any> = [], action: any) => {
        switch (action.type) {
            case "AXIOS_UPLOAD_PROGRESS":
            case "AXIOS_DOWNLOAD_PROGRESS":
                return [action.payload]
        }
        return previousState
    }

    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        activeProject: projectReducer,
        infoArea: infoAreaReducer,
    })
    const resettableAppReducer = (state: any, action: any) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action)

    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                // add your own sagas here
            ].map(fork)
        )
    }
    const sagaMiddleware = createSagaMiddleware()

    const composeEnhancers =
        (process.env.NODE_ENV === "development" &&
            typeof window !== "undefined" &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: true,
                traceLimit: 25,
            })) ||
        compose

    const store = createStore(
        resettableAppReducer,
        {
            /* set your initial state here */
        },
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history)
                // add your own middlewares here
            )
            // add your own enhancers here
        )
    )
    sagaMiddleware.run(saga)

    // register resources if page reloaeded without activating
    const collections = activeProject?.api?.collections || []
    collections.forEach((c: any) => {
        store.dispatch(
            registerResource({
                name: "_/" + activeProject.api.namespace + "/" + c.name,
                hasList: true,
                hasEdit: true,
                hasCreate: true,
                hasShow: false,
            })
        )
    })
    return store
}
