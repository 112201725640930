import React from "react"
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    Labeled,
    DateField,
    FunctionField,
    ChipField,
    SimpleForm,
    TabbedForm,
    FormTab,
    TextInput,
    PasswordInput,
    SelectInput,
    SearchInput,
    Edit,
    Create,
    required,
    regex,
    email,
    Filter,
    ReferenceManyField,
    SingleFieldList,
    useLocale,
} from "react-admin"
import { Typography } from "@material-ui/core"
import { DateTimeFormatOptions, DateFormatOptions } from "./../i18n"
import { JSONExporter } from "../utils"

const ROLE_ADMIN_DISPLAY = (
    <span style={{ color: "darkred" }}>Administrator</span>
)
const ROLE_EDITOR_DISPLAY = "Editor"
const ROLE_USER_DISPLAY = <span style={{ opacity: 0.5 }}>User</span>

const roles = [
    { id: 0, name: ROLE_ADMIN_DISPLAY },
    { id: 1, name: ROLE_EDITOR_DISPLAY },
    { id: 2, name: ROLE_USER_DISPLAY },
]
const getRoleDescr = (record: any) => {
    switch (record.role) {
        case 0:
            return ROLE_ADMIN_DISPLAY
        case 1:
            return ROLE_EDITOR_DISPLAY
        case 2:
            return ROLE_USER_DISPLAY
    }
}

function UserFilter(props: any) {
    return (
        <div style={{ marginLeft: 20 }}>
            <Filter {...props}>
                <SearchInput source="_search" alwaysOn />
                <SelectInput source="role" choices={roles} />
            </Filter>
        </div>
    )
}

function UserList(props: object) {
    const locale = useLocale()

    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: "username", order: "ASC" }}
            exporter={JSONExporter("user")}
        >
            {
                /*isMedia("sm")*/ false ? (
                    <SimpleList
                        primaryText={(record: any) => record.username}
                        secondaryText={getRoleDescr}
                        tertiaryText={(record: any) => record.meta?.company}
                    />
                ) : (
                    <Datagrid rowClick="edit">
                        <TextField source="username" />
                        <FunctionField source="role" render={getRoleDescr} />
                        <TextField source="meta.company" />
                        <ReferenceManyField
                            label="resources.user.fields.projects"
                            reference="project"
                            target="users"
                            sortable={false}
                        >
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceManyField>
                        <DateField
                            source="insertTime"
                            locales={locale}
                            options={DateFormatOptions}
                        />
                        <DateField
                            source="updateTime"
                            locales={locale}
                            options={DateFormatOptions}
                        />
                    </Datagrid>
                )
            }
        </List>
    )
}

function ContactForm(props: any) {
    return (
        <div>
            <TextInput resource="user" source="meta.company" fullWidth={true} />
            <TextInput resource="user" source="meta.prename" fullWidth={true} />
            <TextInput resource="user" source="meta.surname" fullWidth={true} />
            <TextInput
                resource="user"
                source="meta.email"
                fullWidth={true}
                validate={[required("required"), email("invalid email format")]}
            />
            <TextInput resource="user" source="meta.phone" fullWidth={true} />
        </div>
    )
}

function UserAside(props: any) {
    const locale = useLocale()

    return (
        <div style={{ width: 250, margin: "1em" }}>
            <Labeled label="resources.user.fields.username" fullWidth={true}>
                <TextField record={props.record} source="username" />
            </Labeled>
            <Labeled label="resources.user.fields.insertTime" fullWidth={true}>
                <DateField
                    record={props.record}
                    source="insertTime"
                    locales={locale}
                    options={DateTimeFormatOptions}
                />
            </Labeled>
            <Labeled label="resources.user.fields.updateTime" fullWidth={true}>
                <DateField
                    record={props.record}
                    source="updateTime"
                    locales={locale}
                    options={DateTimeFormatOptions}
                />
            </Labeled>
        </div>
    )
}

function UserTitle(props: any) {
    return <span>User: {props.record?.username}</span>
}

function UserEdit(props: { id: any; [key: string]: any }) {
    return (
        <Edit
            {...props}
            title={<UserTitle />}
            aside={/*isMedia("sm")*/ false ? undefined : <UserAside />}
        >
            <TabbedForm>
                <FormTab label="wmbasic.tabs.contact">
                    <ContactForm />
                </FormTab>
                <FormTab label="wmbasic.tabs.account">
                    <SelectInput source="role" choices={roles} />
                    <Labeled label="wmbasic.labels.changePassword">
                        <PasswordInput source="password" fullWidth={false} />
                    </Labeled>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

function UserCreate(props: object) {
    return (
        <Create {...props}>
            <SimpleForm>
                <Typography variant="subtitle1">Account</Typography>
                <SelectInput
                    source="role"
                    choices={roles}
                    validate={required("required")}
                />
                <TextInput
                    source="username"
                    fullWidth={false}
                    validate={[
                        required("required"),
                        regex(/^[a-z0-9_-]+$/i, "invalid username"),
                    ]}
                />
                <PasswordInput
                    source="password"
                    fullWidth={false}
                    validate={required("required")}
                />
                <Typography variant="subtitle1">Contact</Typography>
                <ContactForm />
            </SimpleForm>
        </Create>
    )
}

export default {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
}
