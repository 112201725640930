import JwtDecode from "jwt-decode"
import Axios from "axios"

export type Credentials = {
    username: string
    password: string
}

export type Permissions = {
    role?: number
}

export interface TokenProvider {
    getToken(): Promise<string | undefined>
}

export class LoginHandler {
    private apiBase: string
    private credentials?: Credentials
    private token?: string
    private jwt?: {
        exp: number
        type: string
        object: {
            id: string
            username: string
            role: number
            meta: {
                [key: string]: any
            }
        }
    }

    constructor(apiBase: string) {
        this.apiBase = apiBase
        this.getCredentials = this.getCredentials.bind(this)
        this.getProfile = this.getProfile.bind(this)
        this.getToken = this.getToken.bind(this)
        this.clearSession = this.clearSession.bind(this)
        this.restore()
    }

    async login(c: Credentials) {
        this.credentials = c

        const response = await Axios.request({
            url: this.apiBase + "/login",
            method: "post",
            data: this.credentials,
        })
        this.token = response.data.token
        this.save()

        this.parseJwt()
    }

    private parseJwt() {
        if (this.token !== undefined) {
            this.jwt = JwtDecode(this.token)
        }
    }

    private save() {
        localStorage.setItem(
            "login",
            JSON.stringify({
                credentials: this.credentials,
                token: this.token,
            })
        )
    }

    private restore() {
        let l = localStorage.getItem("login")
        if (l) {
            let o = JSON.parse(l)
            this.credentials = o.credentials
            this.token = o.token
            this.parseJwt()
        }
    }

    async getToken() {
        if (!this.token || !this.credentials) {
            throw new Error("access denied")
        }

        const timeDiff = (this.jwt?.exp || 0) - new Date().getTime() / 1000
        if (timeDiff < 150) {
            await this.login(this.credentials)
        }

        return this.token
    }

    getCredentials() {
        return this.credentials
    }

    clearSession() {
        localStorage.removeItem("login")
        this.token = undefined
        this.jwt = undefined
        this.credentials = undefined
    }

    getProfile() {
        return this.jwt?.object
    }
}

export default function getAuthProvider(url: string) {
    let l = new LoginHandler(url)

    return {
        // needed to get token
        handler: l,

        // called when the user attempts to log in
        login: async (o: Credentials) => {
            return l.login(o)
        },
        // called when the user clicks on the logout button
        logout: () => {
            l.clearSession()
            return Promise.resolve()
        },
        // called when the API returns an error
        checkError: (e: { status: number }) => {
            console.log(e)
            if (e.status === 401 || e.status === 403) {
                l.clearSession()
                return Promise.reject()
            }
            return Promise.resolve()
        },
        // called when the user navigates to a new location, to check for authentication
        checkAuth: async () => {
            const t = await l.getToken()
            if (t) return t
            throw new Error("access denied")
            /*
            return l.getToken() !== undefined
                ? Promise.resolve()
                : Promise.reject()
                */
        },
        // called when the user navigates to a new location, to check for permissions / roles
        getPermissions: (): Promise<Permissions> => {
            const profile = l.getProfile()
            return Promise.resolve({ role: profile?.role })
        },
    }
}
