import React from "react"
import { Login } from "react-admin"

const MyLogin = (props: any) => (
    <Login
        // A random image that changes everyday
        backgroundImage="https://source.unsplash.com/collection/202/1600x900"
        {...props}
    />
)

export default MyLogin
