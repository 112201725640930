import React from "react"
import { Admin, Resource, WithPermissions } from "react-admin"
import getAuthProvider, { Permissions } from "./authProvider"
import getDataProvider from "./dataProvider"
import Theme from "./theme"
import i18nProvider from "./i18n"
import PeopleAltRounded from "@material-ui/icons/PeopleAltRounded"
import Web from "@material-ui/icons/Web"
import { connect, Provider } from "react-redux"
import createAdminStore from "./createAdminStore"
import { createHashHistory } from "history"
import { Route } from "react-router-dom"

import user from "./views/user"
import project from "./views/project"
import collection from "./views/collection"
import Layout from "./views/Layout"
import Login from "./views/Login"

// let the user modify local storage to set another apiBase
let apiBase = "/api/v1"
const storedApiBase = localStorage.getItem("apiBase")
if (storedApiBase) {
    apiBase = storedApiBase
} else {
    localStorage.setItem("apiBase", apiBase)
}

const authProvider = getAuthProvider(apiBase)
const dataProvider = getDataProvider(apiBase, authProvider.handler)

const history = createHashHistory()

const store = createAdminStore({
    authProvider,
    dataProvider,
    history,
})
dataProvider.instance.connectStore(store)

function AdminApp(props: any) {
    const { activeProject } = props

    let customRoutes: Array<any> = []
    let collections = activeProject?.api?.collections || []
    collections.forEach((c: any) => {
        const rName = "_/" + activeProject.api.namespace + "/" + c.name
        customRoutes.push(
            <Route
                exact
                path={"/" + rName}
                component={(routeProps: any) => (
                    <WithPermissions
                        component={collection.list}
                        basePath={"/" + rName}
                        resource={rName}
                        {...routeProps}
                    />
                )}
            />
        )
        customRoutes.push(
            <Route
                exact
                path={"/" + rName + "/create"}
                component={(routeProps: any) => (
                    <WithPermissions
                        component={collection.create}
                        basePath={"/" + rName}
                        resource={rName}
                        {...routeProps}
                    />
                )}
            />
        )
        customRoutes.push(
            <Route
                exact
                path={"/" + rName + "/:id"}
                component={(routeProps: any) => (
                    <WithPermissions
                        component={collection.edit}
                        basePath={"/" + rName}
                        id={decodeURIComponent(routeProps.match?.params?.id)}
                        resource={rName}
                        {...routeProps}
                    />
                )}
            />
        )
    })

    return (
        <Admin
            theme={Theme}
            loginPage={Login}
            authProvider={authProvider}
            dataProvider={dataProvider}
            history={history}
            title={activeProject?.name ?? "WMBasic"}
            layout={Layout}
            customRoutes={customRoutes}
            i18nProvider={i18nProvider}
        >
            {(permissions: Permissions) => {
                let resources = [
                    <Resource
                        name="project"
                        icon={Web}
                        list={project.list}
                        edit={permissions.role === 0 ? project.edit : undefined}
                        create={
                            permissions.role === 0 ? project.create : undefined
                        }
                    />,
                    permissions.role === 0 ? (
                        <Resource
                            name="user"
                            icon={PeopleAltRounded}
                            {...user}
                        />
                    ) : (
                        <Resource name="user" />
                    ),
                ]

                return resources
            }}
        </Admin>
    )
}

const ConnectedAdminApp = connect((state: any) => {
    return {
        activeProject: state.activeProject,
    }
})(AdminApp)

function App() {
    return (
        <Provider store={store}>
            <ConnectedAdminApp />
        </Provider>
    )
}

export default App
