import React, { Fragment } from "react"
import {
    List,
    Datagrid,
    FunctionField,
    TextField,
    DateField,
    ImageField,
    FileField,
    RichTextField,
    SimpleForm,
    TextInput,
    NumberInput,
    DateInput,
    ImageInput,
    FileInput,
    ArrayInput,
    SimpleFormIterator,
    Edit,
    Create,
    useLocale,
    SimpleList,
    BooleanInput,
    BooleanField,
    SelectArrayInput,
    ChipField,
    SelectField,
    SelectInput,
} from "react-admin"
import RichTextInput from "ra-input-rich-text"
import { Typography } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { connect } from "react-redux"
import {
    sanitizeProps,
    getCollectionFieldConfig,
    JSONExporter,
} from "./../utils"
import get from "lodash/get"
import set from "lodash/set"
import Twig from "twig"
import Icon from "@material-ui/core/Icon"

type CollectionType = {
    name: string
    fields?: Array<{
        name: string
        type: string
        meta?: any
    }>
    meta?: any
    yourPermissions: {
        get: boolean
        post: boolean
        put: boolean
        delete: boolean
    }
}

function getCurrentCollection(props: any): CollectionType | undefined {
    const { activeProject, resource } = props
    const collectionName = resource.replace(/.*\//, "")
    const collections = activeProject?.api?.collections || []
    for (let i = 0; i < collections.length; i++) {
        if (collections[i].name === collectionName) {
            collections[i].yourPermissions =
                activeProject.yourPermissions &&
                activeProject.yourPermissions[collectionName]
            return collections[i]
        }
    }
}

// simpleList need a function, so wrapped here
function renderFieldFunction(
    srcBase: string,
    collection: CollectionType | undefined,
    fieldConf: any
): Function | undefined {
    if (!fieldConf) return undefined

    if (typeof fieldConf === "string") {
        fieldConf = { source: fieldConf }
    }

    let conf = getCollectionFieldConfig(collection, fieldConf.source)
    if (!conf) conf = {}
    conf.source = fieldConf.source
    conf.meta = { ...conf.meta, ...fieldConf }

    return (record: any) => (
        <CollectionField
            srcBase={srcBase}
            source={conf.source}
            config={conf}
            record={record}
        />
    )
}

function CollectionField(props: any) {
    const f = props.config || {}
    const locale = useLocale()

    if (f.meta?.twig) {
        // TODO optimize twig rendering, parse template once
        const t = Twig.twig({ data: f.meta.twig })
        return (
            <FunctionField
                record={props.record}
                render={(record: any) => record && t.render(record)}
            />
        )
    }

    if (f.meta?.valueMap) {
        return (
            <FunctionField
                record={props.record}
                render={(record: any) => {
                    let elements: any[] = []
                    let k = get(record, props.source)
                    if (k === true) {
                        k = "true"
                    }
                    if (k === false || typeof k === "undefined") {
                        k = "false"
                    }
                    let valConf = f.meta.valueMap[k]
                    if (valConf?.muiIcon) {
                        elements.push(
                            <Icon
                                key={"valueMap-icon-" + Math.random()}
                                style={valConf.style}
                            >
                                {valConf.muiIcon}
                            </Icon>
                        )
                    }
                    return elements
                }}
            />
        )
    }

    switch (f.meta?.widget || f.type) {
        case "boolean":
        case "checkbox":
            return (
                <BooleanField
                    source={props.source}
                    record={props.record}
                    {...f.meta?.fieldProps}
                />
            )
        case "select":
            return (
                <SelectField
                    source={props.source}
                    record={props.record}
                    {...f.meta?.fieldProps}
                    choices={f.meta?.choices}
                    optionText={(c: any) =>
                        typeof c.name === "object" ? c.name[locale] : c.name
                    }
                />
            )
        case "selectArray":
            return (
                <FunctionField
                    source={props.source}
                    record={props.record}
                    render={(r: any) => {
                        let v = get(r, props.source)
                        if (!Array.isArray(v)) {
                            v = [v]
                        }
                        let arr: any[] = []
                        v.forEach((val: any) => {
                            let style = null
                            f.meta?.choices?.forEach((c: any) => {
                                if (c.id === val) {
                                    val =
                                        typeof c.name === "object"
                                            ? c.name[locale]
                                            : c.name
                                    style = c.chipStyle
                                }
                            })
                            arr.push(
                                <ChipField
                                    key={"chip-selectArray-" + Math.random()}
                                    source="value"
                                    record={{ value: val }}
                                    style={style}
                                    {...f.meta?.fieldProps}
                                />
                            )
                        })
                        return arr
                    }}
                />
            )
        case "date":
            return (
                <DateField
                    source={props.source}
                    record={props.record}
                    {...f.meta?.fieldProps}
                />
            )
        case "file":
        case "image":
            // add api base for absolute path
            const src: string = props.source + ".src"
            let record = props.record

            const oldVal: string = get(props.record, src)
            if (
                typeof oldVal === "string" &&
                !oldVal.startsWith("data:") &&
                !oldVal.startsWith("blob:")
            ) {
                record = {}
                set(
                    record,
                    src,
                    props.srcBase +
                        "/" +
                        props.record?.id +
                        "/" +
                        oldVal +
                        ((f.meta?.widget || f.type) === "image"
                            ? props.imageFilterSuffix ?? ""
                            : "")
                )
            }

            if ((f.meta?.widget || f.type) === "file") {
                const title: string = props.source + ".path"
                return (
                    <FileField
                        source={src}
                        title={title}
                        record={record}
                        {...f.meta?.fieldProps}
                    />
                )
            }

            return (
                <ImageField
                    source={src}
                    record={record}
                    {...f.meta?.fieldProps}
                />
            )
        case "richtext":
            return (
                <RichTextField
                    source={props.source}
                    record={props.record}
                    {...f.meta?.fieldProps}
                />
            )
        default:
            return (
                <TextField
                    source={props.source}
                    record={props.record}
                    {...f.meta?.fieldProps}
                />
            )
    }
}

function CollectionList(props: any) {
    const collection = getCurrentCollection(props)
    const locale = useLocale()
    let key: number = 0
    const apiBase = localStorage.getItem("apiBase")
    const srcBase = apiBase + props.basePath
    const imageFilterSuffix = collection?.meta?.defaultImageFilter
        ? "?filter=" + collection?.meta?.defaultImageFilter
        : ""

    let views = collection?.meta?.views || []
    let view
    for (let i = 0; i < views.length; i++) {
        // dont break loop, hooks need same order
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (useMediaQuery(views[i].mediaQuery || "(min-width:0px)")) {
            if (!view) view = views[i]
        }
    }
    if (!view) {
        view = { type: "table", columns: [{ source: "id" }] }
    }
    let ListView
    switch (view.type) {
        case "simpleList":
            ListView = (
                <SimpleList
                    linkType={collection?.yourPermissions?.post ? "edit" : null}
                    primaryText={renderFieldFunction(
                        srcBase,
                        collection,
                        view.primaryText
                    )}
                    secondaryText={renderFieldFunction(
                        srcBase,
                        collection,
                        view.secondaryText
                    )}
                    tertiaryText={renderFieldFunction(
                        srcBase,
                        collection,
                        view.tertiaryText
                    )}
                />
            )
            break
        default:
            let columns = view.columns
            // TODO default columns

            ListView = (
                <Datagrid
                    rowClick={collection?.yourPermissions?.post ? "edit" : null}
                >
                    {(columns || []).map((f: any) => {
                        key++

                        if (typeof f === "string") {
                            f = { source: f }
                        }

                        let conf = getCollectionFieldConfig(
                            collection,
                            f.source
                        )
                        conf = Object.assign({}, conf)
                        conf.source = f.source
                        conf.meta = { ...conf.meta, ...f }

                        return (
                            <CollectionField
                                key={f.source + key}
                                label={
                                    (f.label && f.label[locale]) ||
                                    (conf?.meta?.label &&
                                        conf.meta.label[locale])
                                }
                                srcBase={srcBase}
                                source={f.source}
                                config={conf}
                                imageFilterSuffix={imageFilterSuffix}
                            />
                        )
                    })}
                </Datagrid>
            )
    }

    return (
        <List
            {...sanitizeProps(props)}
            hasList={collection?.yourPermissions?.get ?? false}
            hasShow={false}
            hasCreate={collection?.yourPermissions?.post ?? false}
            hasEdit={collection?.yourPermissions?.put ?? false}
            bulkActionButtons={
                collection?.yourPermissions?.delete ? undefined : false
            }
            exporter={JSONExporter(
                props?.resource?.replace(/^_\//, "").replace(/\//, "_")
            )}
        >
            {ListView}
        </List>
    )
}
const CollectionListConnected = connect((state: any) => ({
    activeProject: state.activeProject,
}))(CollectionList)

function CollectionInput(props: any) {
    const locale = useLocale()
    const f = props.config
    let key = 0

    const fullWidth = f.meta?.inputProps?.fullWidth !== false

    if (
        props.isEdit
            ? f.meta?.edit?.show !== false
            : f.meta?.create?.show !== false
    ) {
        const label =
            typeof f.meta?.label === "object"
                ? f.meta?.label[locale]
                : f.meta?.label
        const helperText =
            typeof f.meta?.helperText === "object"
                ? f.meta?.helperText[locale]
                : f.meta?.helperText
        switch (f.meta?.widget || f.type) {
            case "boolean":
            case "checkbox":
                return (
                    <BooleanInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    />
                )
            case "select":
                return (
                    <SelectInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        choices={f.meta?.choices}
                        optionText={(r: any) =>
                            typeof r.name === "object" ? r.name[locale] : r.name
                        }
                        {...f.meta?.inputProps}
                    />
                )
            case "selectArray":
                return (
                    <SelectArrayInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        choices={f.meta?.choices}
                        optionText={(r: any) =>
                            typeof r.name === "object" ? r.name[locale] : r.name
                        }
                        {...f.meta?.inputProps}
                    />
                )
            case "date":
                return (
                    <DateInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    />
                )
            case "file":
                return (
                    <Fragment>
                        <FileInput
                            label={label}
                            helperText={helperText}
                            source={props.source}
                            fullWidth={fullWidth}
                            {...f.meta?.inputProps}
                            format={(v: any) => {
                                const oldVal: string = v?.src
                                if (
                                    typeof oldVal === "string" &&
                                    !oldVal.startsWith("data:") &&
                                    !oldVal.startsWith("blob:")
                                ) {
                                    return {
                                        ...v,
                                        src: props.srcBase + "/" + oldVal,
                                    }
                                }
                                return v
                            }}
                        >
                            <FileField source="src" title="path" />
                        </FileInput>
                    </Fragment>
                )
            case "image":
                return (
                    <Fragment>
                        <ImageInput
                            label={label}
                            helperText={helperText}
                            source={props.source}
                            fullWidth={fullWidth}
                            {...f.meta?.inputProps}
                            format={(v: any) => {
                                const oldVal: string = v?.src
                                if (
                                    typeof oldVal === "string" &&
                                    !oldVal.startsWith("data:") &&
                                    !oldVal.startsWith("blob:")
                                ) {
                                    return {
                                        ...v,
                                        src:
                                            props.srcBase +
                                            "/" +
                                            oldVal +
                                            (props.imageFilterSuffix ?? ""),
                                    }
                                }
                                return v
                            }}
                        >
                            <ImageField source="src" />
                        </ImageInput>
                    </Fragment>
                )
            case "richtext":
                return (
                    <RichTextInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    />
                )
            case "object":
                return (
                    <Fragment>
                        <Typography variant="h6">{label}</Typography>
                        {(
                            (Array.isArray(f.subFields) && f.subFields) ||
                            []
                        ).map((s: any) => {
                            key++
                            return (
                                <CollectionInput
                                    key={
                                        props.source + "_" + s.name + "_" + key
                                    }
                                    source={props.source + "." + s.name}
                                    config={s}
                                    srcBase={props.srcBase}
                                />
                            )
                        })}
                    </Fragment>
                )

            case "object[]":
                return (
                    <ArrayInput
                        label={
                            <div
                                style={{
                                    fontSize: "2em",
                                    fontWeight: "bold",
                                    marginTop: "-20px",
                                }}
                            >
                                {label}
                            </div>
                        }
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    >
                        <SimpleFormIterator>
                            {(
                                (Array.isArray(f.subFields) && f.subFields) ||
                                []
                            ).map((s: any) => {
                                key++
                                return (
                                    <CollectionInput
                                        key={
                                            props.source +
                                            "_" +
                                            s.name +
                                            "_" +
                                            key
                                        }
                                        source={s.name}
                                        config={s}
                                        srcBase={props.srcBase}
                                    />
                                )
                            })}
                        </SimpleFormIterator>
                    </ArrayInput>
                )

            case "number":
                return (
                    <NumberInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    />
                )

            default:
                return (
                    <TextInput
                        label={label}
                        helperText={helperText}
                        source={props.source}
                        fullWidth={fullWidth}
                        {...f.meta?.inputProps}
                    />
                )
        }
    }
    return <Fragment />
}

function CollectionForm(props: any) {
    const collection = getCurrentCollection(props)
    let key: number = 0
    const apiBase = localStorage.getItem("apiBase")
    const srcBase = apiBase + props.basePath + "/" + props.record?.id
    const imageFilterSuffix = collection?.meta?.defaultImageFilter
        ? "?filter=" + collection?.meta?.defaultImageFilter
        : ""

    return (
        <SimpleForm {...sanitizeProps(props)}>
            {(collection?.fields || []).map((f: any) => {
                key++
                return (
                    <CollectionInput
                        key={f.name + key}
                        source={f.name}
                        config={f}
                        isEdit={props.isEdit}
                        srcBase={srcBase}
                        imageFilterSuffix={imageFilterSuffix}
                    />
                )
            })}
        </SimpleForm>
    )
}

const CollectionFormConnected = connect((state: any) => ({
    activeProject: state.activeProject,
}))(CollectionForm)

function CollectionEdit(props: any) {
    return (
        <Edit {...props}>
            <CollectionFormConnected isEdit={true} />
        </Edit>
    )
}

function CollectionCreate(props: any) {
    return (
        <Create {...props}>
            <CollectionFormConnected isEdit={false} />
        </Create>
    )
}

export default {
    list: CollectionListConnected,
    edit: CollectionEdit,
    create: CollectionCreate,
}
