import React from "react"
import { Layout, AppBar } from "react-admin"
import { Typography, LinearProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Menu from "./Menu"
import { connect } from "react-redux"
import { sanitizeProps } from "./../utils"

const useStyles = makeStyles({
    projectTitle: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    title: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    spacer: {
        flex: 1,
    },
    progress: {
        width: 100,
    },
})

const MyAppBar = (props: any) => {
    let loadedBytes = 0
    let totalBytes = 0

    props.infoArea?.forEach((e: any) => {
        try {
            if (e.total > 0) {
                loadedBytes += e.loaded
                totalBytes += e.total
            }
        } catch (_e) {}
    })

    let completed = 0
    if (totalBytes > 0) {
        completed = (loadedBytes / totalBytes) * 100
    }

    const classes = useStyles()
    return (
        <AppBar {...sanitizeProps(props)}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.projectTitle}
            >
                {props?.activeProject?.name}
            </Typography>
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                className={classes.title}
            />
            <span className={classes.spacer} />
            {completed && completed < 100 ? (
                <LinearProgress
                    color="primary"
                    className={classes.progress}
                    value={completed}
                />
            ) : undefined}
        </AppBar>
    )
}

const MyAppBarConnected = connect((state: any) => ({
    activeProject: state.activeProject,
    infoArea: state.infoArea,
}))(MyAppBar)

const MyLayout = (props: any) => (
    <Layout {...props} menu={Menu} appBar={MyAppBarConnected} />
)

export default MyLayout
