import React, { createElement } from "react"
import { useSelector, connect } from "react-redux"
import { useMediaQuery } from "@material-ui/core"
import {
    MenuItemLink,
    getResources,
    useLocale,
    useSetLocale,
    useTranslate,
} from "react-admin"
import { withRouter } from "react-router-dom"
import Label from "@material-ui/icons/Label"
import Icon from "@material-ui/core/Icon"
import Button from "@material-ui/core/Button"

function CollectionIcon(props: any) {
    const { collection } = props
    return collection.meta?.muiIcon ? (
        <Icon>{collection.meta?.muiIcon}</Icon>
    ) : (
        createElement(Label)
    )
}

const Menu = (props: any) => {
    const { onMenuClick, logout, activeProject } = props
    const collections = activeProject?.api?.collections || {}

    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"))
    const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
    const resources: Array<any> = useSelector(getResources)

    const locale = useLocale()
    const setLocale = useSetLocale()
    const translate = useTranslate()

    return (
        <div>
            {resources.map((resource) =>
                resource.name.startsWith("_") ? undefined : (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={translate(
                            `resources.${resource.name}.name`,
                            {
                                smart_count: 2,
                            }
                        )}
                        leftIcon={
                            resource.icon
                                ? createElement(resource.icon)
                                : createElement(Label)
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                )
            )}
            <hr style={{ marginTop: 20, marginBottom: 20, width: "100%" }}></hr>
            {activeProject
                ? (Array.isArray(collections)
                      ? collections
                      : []
                  ).map((c: any) =>
                      activeProject.yourPermissions &&
                      activeProject.yourPermissions[c.name] &&
                      activeProject.yourPermissions[c.name].get ? (
                          <MenuItemLink
                              key={activeProject.id + "/" + c.name}
                              to={`/_/${activeProject.api.namespace}/${c.name}`}
                              primaryText={
                                  (typeof c.meta?.label === "object" &&
                                      c.meta?.label[locale]) ||
                                  c.name
                              }
                              leftIcon={<CollectionIcon collection={c} />}
                              onClick={onMenuClick}
                              sidebarIsOpen={open}
                          />
                      ) : null
                  )
                : undefined}
            {isXSmall && logout}
            <div style={{ flexDirection: "row", marginTop: 30 }}>
                <Button
                    disabled={locale === "de"}
                    onClick={() => setLocale("de")}
                >
                    DE
                </Button>
                <Button
                    disabled={locale === "en"}
                    onClick={() => setLocale("en")}
                >
                    EN
                </Button>
            </div>
        </div>
    )
}

const MenuConnected = connect((state: any) => {
    return {
        activeProject: state.activeProject,
    }
})(Menu)

export default withRouter(MenuConnected)
