import polyglotI18nProvider from "ra-i18n-polyglot"
import { TranslationMessages } from "ra-core"
import englishMessages from "ra-language-english"
// @ts-ignore
import germanMessages from "ra-language-german"

const messages: {
    [key: string]: TranslationMessages
} = {
    de: {
        ...germanMessages,
        resources: {
            project: {
                name: "Projekt |||| Projekte",
                fields: {
                    id: "ID",
                    insertTime: "erstellt am",
                    updateTime: "aktualisiert am",
                    name: "Name",
                    description: "Beschreibung",
                    users: "Benutzer",
                    configFile: "Konfigurations-Datei",
                },
            },
            user: {
                name: "Benutzer",
                fields: {
                    id: "ID",
                    insertTime: "erstellt am",
                    updateTime: "aktualisiert am",
                    username: "Benutzername",
                    role: "Rolle",
                    projects: "Projekte",
                    meta: {
                        company: "Firma",
                        prename: "Vorname",
                        surname: "Nachname",
                        email: "Email",
                        phone: "Telefon",
                    },
                },
            },
        },
        wmbasic: {
            tabs: {
                config: "Konfiguration",
                contact: "Kontakt",
                account: "Zugang",
                error: "Fehler",
                collections: "Kollektionen",
            },
            labels: {
                meta: "Metadaten",
                changePassword: "Passwort ändern",
                status: "Status",
            },
            messages: {
                activeProjectSet: "aktives Projekt: %{name}",
            },
        },
    },
    en: {
        ...englishMessages,
        resources: {
            project: {
                name: "Project |||| Projects",
                fields: {
                    id: "ID",
                    insertTime: "created on",
                    updateTime: "updated on",
                    name: "Name",
                    description: "Description",
                    users: "Users",
                    configFile: "Configuration File",
                },
            },
            user: {
                name: "Users",
                fields: {
                    id: "ID",
                    insertTime: "created on",
                    updateTime: "updated on",
                    username: "Username",
                    role: "Role",
                    projects: "Projects",
                    meta: {
                        company: "Company",
                        prename: "Prename",
                        surname: "Surname",
                        email: "Email",
                        phone: "Phone",
                    },
                },
            },
        },
        wmbasic: {
            tabs: {
                config: "Configuration",
                contact: "Contact",
                account: "Account",
                error: "Error",
                collections: "Collections",
            },
            labels: {
                meta: "Meta Data",
                changePassword: "change password",
                status: "State",
            },
            messages: {
                activeProjectSet: "active project: %{name}",
            },
        },
    },
}
messages["de"]["ra"]["message"]["details"] = "Details"
messages["en"]["ra"]["message"]["details"] = "Details"
messages["de"]["ra"]["notification"]["logged_out"] = "Sie wurden ausgeloggt."
messages["en"]["ra"]["notification"]["logged_out"] = "You are logged out."
messages["de"]["ra"]["notification"]["auth_check_error"] =
    "Authentifizierungsfehler"
messages["en"]["ra"]["notification"]["auth_check_error"] =
    "authentication error"
messages["de"]["ra"]["page"]["empty"] = "keine Einträge vorhanden"
messages["en"]["ra"]["page"]["empty"] = "no entries available"
messages["de"]["ra"]["page"]["invite"] = "Erstellen Sie den Ersten."
messages["en"]["ra"]["page"]["invite"] = "Please create the first entry."
messages["de"]["ra"]["action"]["unselect"] = "abwählen"
//messages["en"]["ra"]["action"]["unselect"] = "unselect"

const i18nProvider = polyglotI18nProvider(
    (locale: string) => messages[locale],
    "de"
)

export default i18nProvider

export const DateFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
}
export const DateTimeFormatOptions = {
    ...DateFormatOptions,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
}
