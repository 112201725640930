import React, { useState } from "react"
import {
    List,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Labeled,
    TextField,
    DateField,
    TabbedForm,
    FormTab,
    Edit,
    Create,
    TextInput,
    ReferenceArrayInput,
    EditButton,
    Filter,
    SearchInput,
    ReferenceInput,
    SelectInput,
    AutocompleteArrayInput,
    useNotify,
    registerResource,
    Toolbar,
} from "react-admin"
import { Typography } from "@material-ui/core"
import IsOnline from "@material-ui/icons/Public"
import IsOffline from "@material-ui/icons/Block"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import YAML from "yaml"
import { JSONExporter } from "../utils"

const actions = {
    setActiveProject: (record: any) => ({
        type: "SET_ACTIVE_PROJECT",
        payload: record,
    }),
}

const isOnlineC = <IsOnline style={{ color: "darkgreen" }} />
const isOfflineC = <IsOffline style={{ color: "darkred" }} />

const useStyles = makeStyles({
    cardRoot: {
        width: "100%",
        maxWidth: 400,
        display: "inline-block",
        margin: "0.5em",
        minHeight: 420,
        position: "relative",
    },
    cardMedia: {
        height: 200,
    },
    cardActions: {
        position: "absolute",
        bottom: 0,
        right: 0,
    },
})

function ProjectGrid(props: any) {
    const classes = useStyles()
    const notify = useNotify()

    const { ids, data, basePath, permissions } = props

    return (
        <div style={{ marginRight: "1em" }}>
            {ids.map((id: any) => (
                <Card
                    raised={id === props.activeProject?.id}
                    key={id}
                    className={classes.cardRoot}
                    style={{
                        minHeight: permissions?.role === 0 ? undefined : 350,
                    }}
                >
                    <CardActionArea
                        onClick={() => {
                            props.registerCollections(data[id])
                            props.setActiveProject(data[id])
                            notify(
                                "wmbasic.messages.activeProjectSet",
                                "info",
                                {
                                    name: data[id].name,
                                }
                            )
                        }}
                    >
                        <CardMedia
                            className={classes.cardMedia}
                            image={data[id].api?.meta?.imageUrl}
                            title={data[id].name}
                        >
                            {data[id].api?.meta?.imageUrl ? undefined : (
                                <Typography
                                    variant="body1"
                                    style={{
                                        fontSize: 100,
                                        padding: 50,
                                        opacity: 0.3,
                                        fontWeight: "bolder",
                                    }}
                                    color="primary"
                                >
                                    {data[id].name}
                                </Typography>
                            )}
                        </CardMedia>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="h2"
                            >
                                {data[id].api?.isOnline
                                    ? isOnlineC
                                    : isOfflineC}
                                &nbsp;
                                {data[id].name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                            >
                                {data[id].description}
                            </Typography>
                        </CardContent>
                        {permissions?.role === 0 ? (
                            <CardContent>
                                <Labeled label="resources.user.name">
                                    <ReferenceArrayField
                                        reference="user"
                                        source="users"
                                        record={data[id]}
                                        basePath="/user"
                                    >
                                        <SingleFieldList>
                                            <ChipField
                                                source="username"
                                                color="secondary"
                                                size="small"
                                            />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </Labeled>
                            </CardContent>
                        ) : undefined}
                    </CardActionArea>

                    <CardActions className={classes.cardActions}>
                        <EditButton
                            resource="posts"
                            basePath={basePath}
                            record={data[id]}
                        />
                    </CardActions>
                </Card>
            ))}
        </div>
    )
}
ProjectGrid.defaultProps = {
    data: {},
    ids: [],
}

const ProjectGridConnected = connect(
    (state: any) => ({ activeProject: state.activeProject }),
    (dispatch) => ({
        ...bindActionCreators(actions, dispatch),
        registerCollections: (project: any) => {
            const collections = project.api?.collections || []
            collections.forEach((c: any) => {
                dispatch(
                    registerResource({
                        name: "_/" + project.api.namespace + "/" + c.name,
                        hasList: true,
                        hasEdit: true,
                        hasCreate: true,
                        hasShow: false,
                    })
                )
            })
        },
    })
)(ProjectGrid)

function ProjectFilter(props: any) {
    return (
        <div style={{ marginLeft: 20 }}>
            <Filter {...props}>
                <SearchInput source="_search" alwaysOn />
                {props.permissions?.role === 0 ? (
                    <ReferenceInput
                        source="users"
                        reference="user"
                        sort={{ field: "username", order: "ASC" }}
                        perPage={10000}
                    >
                        <SelectInput optionText="username" />
                    </ReferenceInput>
                ) : undefined}
            </Filter>
        </div>
    )
}

function ProjectList(props: any) {
    return (
        <List
            {...props}
            filters={<ProjectFilter permissions={props.permissions} />}
            sort={{ field: "updateTime", order: "DESC" }}
            exporter={JSONExporter("project")}
        >
            <ProjectGridConnected permissions={props.permissions} />
        </List>
    )

    /*
    return (
        <List {...props}>
            {isMedia("sm") ? (
                <SimpleList
                    primaryText={(record: any) => record.name}
                    secondaryText={(record: any) => record.description}
                    rightIcon={(record: any) =>
                        record?.api?.isOnline ? isOnlineC : isOfflineC
                    }
                />
            ) : (
                <Datagrid rowClick="edit">
                    <FunctionField
                        label="Is online"
                        source="api.isOnline"
                        render={(record: any) =>
                            record?.api?.isOnline ? isOnlineC : isOfflineC
                        }
                    />
                    <TextField source="name" />
                    <TextField source="description" />
                    <ReferenceArrayField
                        laben="Users"
                        reference="user"
                        source="users"
                    >
                        <SingleFieldList>
                            <ChipField
                                source="username"
                                color="secondary"
                                size="small"
                            />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateField label="Inserted" source="insertTime" />
                    <DateField label="Updated" source="updateTime" />
                </Datagrid>
            )}
        </List>
    )
    */
}

function ProjectAside(props: any) {
    const iUrl = props.imageUrl ?? props.record?.api?.meta?.imageUrl
    const img = iUrl ? (
        <img
            src={iUrl}
            title="project image"
            alt="project"
            style={{ width: "100%" }}
        />
    ) : undefined
    return (
        <div style={{ width: 250, margin: "1em" }}>
            <Labeled label="resources.project.fields.id" fullWidth={true}>
                <TextField record={props.record} source="id" />
            </Labeled>
            <Labeled
                label="resources.project.fields.insertTime"
                fullWidth={true}
            >
                <DateField
                    record={props.record}
                    source="insertTime"
                    showTime={true}
                />
            </Labeled>
            <Labeled
                label="resources.project.fields.updateTime"
                fullWidth={true}
            >
                <DateField
                    record={props.record}
                    source="updateTime"
                    showTime={true}
                />
            </Labeled>
            <Labeled label="wmbasic.labels.status" fullWidth={true}>
                {props.record?.api?.isOnline ? isOnlineC : isOfflineC}
            </Labeled>
            {img}
        </div>
    )
}

function ProjectForm(props: any) {
    return (
        <TabbedForm
            redirect="edit"
            {...props}
            toolbar={<Toolbar alwaysEnableSaveButton />}
        >
            <FormTab label="wmbasic.tabs.config">
                <TextInput source="name" fullWidth={true} />
                <TextInput source="description" fullWidth={true} />
                <ReferenceArrayInput
                    source="users"
                    reference="user"
                    perPage={10000}
                    fullWidth={true}
                    filterToQuery={(q: string) => ({ _search: q })}
                >
                    <AutocompleteArrayInput
                        fullWith={true}
                        optionText={(r: any) =>
                            r?.username +
                            " (" +
                            (r?.meta?.company ||
                                r?.meta?.prename + " " + r?.meta?.surname) +
                            ")"
                        }
                    />
                </ReferenceArrayInput>
                <TextInput source="configFile" fullWidth={true} />
                <TextInput source="namespace" fullWidth={true} />
            </FormTab>
            {props.record?.api?.errors ? (
                <FormTab label="wmbasic.tabs.error">
                    <table
                        cellPadding={10}
                        style={{ margin: 20, width: 800, maxWidth: "100%" }}
                    >
                        <tr>
                            <th>Collection</th>
                            <th>Context</th>
                            <th>Error</th>
                        </tr>
                        {props.record.api.errors.map((e: any) => (
                            <tr>
                                <td>{e.collection}</td>
                                <td>{e.context}</td>
                                <td>{e.error}</td>
                            </tr>
                        ))}
                    </table>
                </FormTab>
            ) : undefined}
            {props.record?.api?.collections ? (
                <FormTab label="wmbasic.tabs.collections">
                    {props.record.api.collections.map((c: any) => (
                        <>
                            <Typography variant="h5">
                                _/{props.record.api.namespace}/{c.name}
                            </Typography>
                            {c.meta ? (
                                <div style={{ padding: 10 }}>
                                    <Typography variant="h6">Meta</Typography>
                                    <div
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            lineHeight: "1.5em",
                                        }}
                                    >
                                        {YAML.stringify(c.meta, { indent: 8 })}
                                    </div>
                                </div>
                            ) : undefined}
                            <table
                                cellPadding={10}
                                cellSpacing={0}
                                style={{
                                    margin: 20,
                                    width: 800,
                                    maxWidth: "100%",
                                    lineHeight: "1.5em",
                                }}
                            >
                                <tr>
                                    <th style={{ textAlign: "left" }}>Field</th>
                                    <th style={{ textAlign: "left" }}>Type</th>
                                    <th style={{ textAlign: "left" }}>Index</th>
                                    <th style={{ textAlign: "left" }}>Meta</th>
                                </tr>
                                {c.fields?.map((f: any) => (
                                    <>
                                        <tr>
                                            <td
                                                style={{
                                                    verticalAlign: "top",
                                                    borderTop: "1px solid #999",
                                                }}
                                            >
                                                {f.name}
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: "top",
                                                    borderTop: "1px solid #999",
                                                }}
                                            >
                                                {f.index}
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: "top",
                                                    borderTop: "1px solid #999",
                                                }}
                                            >
                                                {f.type}
                                            </td>
                                            <td
                                                style={{
                                                    verticalAlign: "top",
                                                    borderTop: "1px solid #999",
                                                    whiteSpace: "pre-wrap",
                                                }}
                                            >
                                                {YAML.stringify(f.meta, {
                                                    indent: 4,
                                                })}
                                            </td>
                                        </tr>
                                        {f.subFields?.map((sf: any) => (
                                            <tr>
                                                <td
                                                    style={{
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    <i
                                                        style={{
                                                            paddingLeft: 20,
                                                        }}
                                                    >
                                                        {sf.name}
                                                    </i>
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    {sf.index}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    {sf.type}
                                                </td>
                                                <td
                                                    style={{
                                                        verticalAlign: "top",
                                                        whiteSpace: "pre-wrap",
                                                    }}
                                                >
                                                    {YAML.stringify(sf.meta, {
                                                        indent: 4,
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </table>
                        </>
                    ))}
                </FormTab>
            ) : undefined}
        </TabbedForm>
    )
}

function ProjectTitle(props: any) {
    return <span>Project: {props.record?.name}</span>
}

function ProjectEdit(props: { id: any; [key: string]: any }) {
    const [imageUrl, setImageUrl] = useState()

    return (
        <Edit
            {...props}
            title={<ProjectTitle />}
            aside={
                /*isMedia("sm")*/ false ? undefined : (
                    <ProjectAside imageUrl={imageUrl} />
                )
            }
        >
            <ProjectForm setImageUrl={setImageUrl} />
        </Edit>
    )
}

function ProjectCreate(props: any) {
    return (
        <Create {...props}>
            <ProjectForm />
        </Create>
    )
}

export default {
    list: ProjectList,
    edit: ProjectEdit,
    create: ProjectCreate,
}
