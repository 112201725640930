import Axios, { AxiosError, AxiosInstance } from "axios"
import { TokenProvider } from "./authProvider"
import { HttpError } from "react-admin"
import { Store } from "redux"

type paginationParam = {
    page: number
    perPage: number
}

type sortParam = {
    field: string
    order: string
}

type listQuery = {
    count?: number
    offset?: number
    limit?: number
    filter?: {
        [key: string]: any
    }
    _search?: string
    sort?: string
}

function axiosProgress(type: string, event: any) {
    return {
        type,
        payload: {
            loaded: event?.loaded,
            total: event?.total,
            lengthComputable: event?.lengthComputable,
            url: event?.target?.responseURL,
            status: event?.target?.status,
        },
    }
}

class DataProvider {
    private axios: () => AxiosInstance
    private store?: Store<any>
    private baseURL: string
    private tokenProvider: TokenProvider

    constructor(apiBase: string, tokenProvider: TokenProvider) {
        this.baseURL = apiBase
        this.tokenProvider = tokenProvider
        this.axios = () =>
            Axios.create({
                baseURL: apiBase,
                //timeout: 10000,
                onUploadProgress: (event: any) => {
                    this.store?.dispatch(
                        axiosProgress("AXIOS_UPLOAD_PROGRESS", event)
                    )
                },
                onDownloadProgress: (event: any) => {
                    this.store?.dispatch(
                        axiosProgress("AXIOS_DOWNLOAD_PROGRESS", event)
                    )
                },
                /* responseType: "json",*/
            })
        this.connectStore = this.connectStore.bind(this)
    }

    connectStore(store: Store<any>) {
        this.store = store
    }

    private formatError(e: AxiosError) {
        return new HttpError(
            `${e.response?.status}: ${
                e.response?.data?.error || e.response?.statusText
            }`,
            e.response?.status,
            e.response?.data
        )
    }

    async getList(
        resource: string,
        params: {
            pagination?: paginationParam
            sort?: sortParam
            filter?: {
                [key: string]: any
            }
        }
    ) {
        let url = resource
        let query: listQuery = {
            count: 1,
        }

        if (params.pagination !== undefined) {
            query.offset =
                (params.pagination.page - 1) * params.pagination.perPage
            query.limit = params.pagination.perPage
        }

        if (params.sort !== undefined) {
            query.sort =
                (params.sort.order === "DESC" ? "-" : "") +
                (params.sort.field === "id" ? "_id" : params.sort.field)
        }

        query.filter = params.filter
        if (query.filter?._search !== undefined) {
            query._search = query.filter._search
            delete query.filter._search
        }

        try {
            const { data, headers } = await this.axios().request({
                url,
                method: "get",
                headers: {
                    "X-Auth-Token": await this.tokenProvider.getToken(),
                },
                params: query,
            })
            return {
                data,
                total: parseInt(headers["x-results-count"]),
            }
        } catch (e) {
            console.log(e)
            throw this.formatError(e)
        }
    }

    async getOne(resource: string, params: { id: any }) {
        try {
            const { data } = await this.axios().request({
                url: `${resource}/${params.id}`,
                method: "get",
                headers: {
                    "X-Auth-Token": await this.tokenProvider.getToken(),
                },
            })
            return { data }
        } catch (e) {
            console.log(e)
            throw this.formatError(e)
        }
    }

    async getMany(
        resource: string,
        params: {
            ids: Array<any>
        }
    ) {
        return this.getList(resource, {
            filter: { id: { $in: params.ids } },
        })
    }

    async getManyReference(
        resource: string,
        params: {
            pagination?: paginationParam
            sort?: sortParam
            filter?: object
            target: string
            id: any
        }
    ) {
        return this.getList(resource, {
            pagination: params.pagination,
            sort: params.sort,
            filter: {
                ...params.filter,
                [params.target]: params.id,
            },
        })
    }

    async update(
        resource: string,
        params: {
            id: any
            data: any
        }
    ) {
        try {
            delete params.data.id // not allowed in update
            delete params.data.insertTime
            delete params.data.updateTime
            if (resource === "project") {
                delete params.data.api
            }

            params.data = await this.transformFiles(params.data)

            const { data } = await this.axios().request({
                url: `${resource}/${params.id}`,
                method: "put",
                headers: {
                    "X-Auth-Token": await this.tokenProvider.getToken(),
                },
                data: params.data,
            })
            return { data }
        } catch (e) {
            console.log(e)
            throw this.formatError(e)
        }
    }

    async updateMany(
        resource: string,
        params: {
            ids: Array<any>
            data: any
        }
    ) {
        for (let i = 0; i < params.ids.length; i++) {
            try {
                await this.update(resource, {
                    id: params.ids[i],
                    data: params.data,
                })
            } catch (e) {
                console.log(e)
                throw this.formatError(e)
            }
        }
        return { data: {} }
    }

    async create(
        resource: string,
        params: {
            data: any
        }
    ) {
        try {
            params.data = await this.transformFiles(params.data)

            const { data } = await this.axios().request({
                url: resource,
                method: "post",
                headers: {
                    "X-Auth-Token": await this.tokenProvider.getToken(),
                },
                data: params.data,
            })

            return {
                data,
                id: data.id,
            }
        } catch (e) {
            console.log(e)
            throw this.formatError(e)
        }
    }

    async delete(
        resource: string,
        params: {
            id: any
        }
    ) {
        try {
            const { data } = await this.axios().request({
                url: `${resource}/${params.id}`,
                method: "delete",
                headers: {
                    "X-Auth-Token": await this.tokenProvider.getToken(),
                },
            })
            return { data }
        } catch (e) {
            console.log(e)
            throw this.formatError(e)
        }
    }

    async deleteMany(
        resource: string,
        params: {
            ids: Array<any>
        }
    ) {
        for (let i = 0; i < params.ids.length; i++) {
            try {
                await this.delete(resource, {
                    id: params.ids[i],
                })
            } catch (e) {
                console.log(e)
                throw this.formatError(e)
            }
        }
        return { data: {} }
    }

    private convertFileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(file)
        })
    }

    private async transformFiles(data: any) {
        switch (true) {
            case typeof data === "object":
                if (data?.rawFile instanceof File) {
                    data.src = await this.convertFileToBase64(data.rawFile)
                    data.path = data.rawFile.path
                    delete data.rawFile
                    break
                }
                for (let key in data) {
                    data[key] = await this.transformFiles(data[key])
                }
                break
            case Array.isArray(data):
                for (let key = 0; key < data.length; key++) {
                    data[key] = await this.transformFiles(data[key])
                }
                break
        }
        return data
    }

    getBaseURL() {
        return this.baseURL
    }

    getProvider() {
        return {
            getList: this.getList.bind(this),
            getOne: this.getOne.bind(this),
            getMany: this.getMany.bind(this),
            getManyReference: this.getManyReference.bind(this),
            update: this.update.bind(this),
            updateMany: this.updateMany.bind(this),
            create: this.create.bind(this),
            delete: this.delete.bind(this),
            deleteMany: this.deleteMany.bind(this),
            instance: this,
        }
    }
}

export default function getDataProvider(
    apiBase: string,
    tokenProvider: TokenProvider
) {
    return new DataProvider(apiBase, tokenProvider).getProvider()
}
